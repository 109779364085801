import React from "react";
import NotFoundArt from "../svgs/NotFoundArt";
import Link from "next/link";
import { Button } from "@jammable/ui-core";

const NotFoundPage = () => {
  return (
    <div className="container-center tw-flex tw-flex-col tw-text-center">
      <NotFoundArt />

      <div className="tw-text-3xl tw-font-bold tw-pt-5 tw-pb-3">
        Page not found!
      </div>
      <div className="tw-max-w-lg tw-text-sm tw-font-semibold">
        Looks like this content no longer exists.
      </div>
      <Button className="tw-rounded-full tw-mt-4" as={Link} href="/" size="md">
        Back Home
      </Button>
    </div>
  );
};

export default NotFoundPage;
